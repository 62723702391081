import { render, staticRenderFns } from "./MatchManageBetting.vue?vue&type=template&id=3fb8e984&scoped=true&"
import script from "./MatchManageBetting.vue?vue&type=script&lang=js&"
export * from "./MatchManageBetting.vue?vue&type=script&lang=js&"
import style0 from "./MatchManageBetting.vue?vue&type=style&index=0&id=3fb8e984&lang=scss&scoped=true&"
import style1 from "./MatchManageBetting.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb8e984",
  null
  
)

export default component.exports